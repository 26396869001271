import { useFirestore, useFirestoreCollection } from "reactfire";
import { ContributionTypes, Event, FirestoreGiftConverter, PaymentStatus } from "../../FirestoreConverters";
import {
  Link,
  // useSearchParams
} from "react-router-dom";
import { and, collection, or, orderBy, query, where } from "firebase/firestore";
import Loading from "../General/Loading";
import GeneralContributionCard from "./GeneralContributionCard";
import GiftingContributionCard from "./GiftingContributionCard";
// import LinkButton from "../General/LinkButton";

interface GiftListProps {
  event: Event;
  showMessages?: boolean;
  showAmounts?: boolean
}

const GiftList = ({event, showAmounts, showMessages}: GiftListProps) => {
  const firestore = useFirestore()
  // const [searchParams, setSearchParams] = useSearchParams()

  // const loadMore = () => {
  //   const base = parseInt(searchParams.get("page") || "1")
  //   const next = base+1
  //   const search = {
  //     page: `${next}`
  //   }
  //   setSearchParams(search)
  // }

  const giftsRef = collection(firestore, `events/${event.id}/gifts`)
    .withConverter(FirestoreGiftConverter)
  const giftsQuery = query(
    giftsRef,
    or(
      where("status", "==", PaymentStatus.PAID),
      and(
        where("status", "in", [
          PaymentStatus.PENDING,
          PaymentStatus.FAILED,
        ]),
        where("type", "==", ContributionTypes.REDEEM),
      )
    ),
    orderBy("giftedAt", "desc"),
    // limit(giftLimit*parseInt(searchParams.get("page") || "1")
  )

  // const giftLimit = 10

  const {status, data: giftsData} = useFirestoreCollection(giftsQuery)

  // Loading the data
  if (status === "loading") return <div className="p-4"><Loading /></div>

  // Something went wrong
  if (status === "error") return <p className="p-2">Sorry, we couldn't load contributions right now. Please try again later.</p>

  // Open event, but no contributions
  if (giftsData.size === 0 && !event.hasEnded) return <>
    <p className="p-2 text-center">No contributions yet.</p>
    <p className="p-2 text-center">Remember to share your jar via the QR Code or link.</p>
  </>

  // Closed event, but no contributions
  if (giftsData.size === 0 && event.hasEnded) return <>
    <p className="p-2 text-center">
      Your jar had no contributions. Why not <Link to="/create-jar" className="font-bold underline">create a new one?</Link>
    </p>
  </>

  return <div className="px-4">
    {giftsData.docs.map((row) => {
      const gift = row.data()
      return <div className=" py-4 border-b last:border-b-0" key={gift.id}>
        {event.isGifting === true ?
          <GiftingContributionCard contribution={gift} showAmounts={showAmounts} showMessages={showMessages} />
        :
          <GeneralContributionCard contribution={gift} />
        }
      </div>
    })}
    {/* {giftsData.size >= (giftLimit*parseInt(searchParams.get("page") || "1")) && <div><LoadMoreButton handler={loadMore} /></div>} */}
  </div>
}

export default GiftList

// interface LMProps {
//   handler: () => void
// }
// const LoadMoreButton = ({handler}: LMProps) => {
//   return (<>
//     <div className="text-center py-8">
//       <LinkButton
//         type="button"
//         clickHandler={handler}
//       >
//         Load More
//       </LinkButton>
//     </div>
//   </>)
// }