import { useOutletContext } from "react-router-dom"
import { MyAccountProps } from "../Page/MyAccount"
import { SubmitHandler, useForm } from "react-hook-form"
import { useFunctions } from "reactfire";
import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import LinkButton from "../General/LinkButton";

interface VerifyIdentityFormProps {
  forename: string;
  surname: string;
  email: string;
  phoneNo: string;
  dateOfBirth: string;
  postCode: string;
}

const VerifyIdentify = () => {
  const {userInfo, setDocumentTitle} = useOutletContext<MyAccountProps>()

  setDocumentTitle("Verify Your Identity")

  const functions = useFunctions()
  const verifyIdentity = httpsCallable<any, any>(functions, "verifyIdentity")

  const { register, handleSubmit, formState: { errors }, setError } = useForm<VerifyIdentityFormProps>()

  const [isLoading, setIsLoading] = useState(false)
  const [verificationRequestSent, setVerificationRequestSent] = useState(false)
  const onSubmit: SubmitHandler<VerifyIdentityFormProps> = async (data) => {
    setIsLoading(true)
    data.phoneNo = `+44${data.phoneNo}`
    console.log(data)
    try {
      await verifyIdentity(data)
      setVerificationRequestSent(true)
    } catch (e: any) {
      console.log(e);
      try {
        const errors = JSON.parse(e.message)
        console.log(errors)
        errors.forEach((e: any) => {
          console.log(e)
          setError(e.field, {message:e.message})
        })
      } catch (e: any) {
        setError('root', {message:"Unable to perform identity verification"})
        setIsLoading(false)
        setVerificationRequestSent(false)
      }
    }
  }

  if (userInfo.IDIsVerified && userInfo.verification !== undefined) {
    return <div className="text-center p-5">
      <h2 className="font-bold text-2xl uppercase">You're verified</h2>
      <p className="text-center max-w-xl mx-auto py-2">
        Your identity has successfully been verified. You will need to re-verify after {userInfo.verification.expiry.toDate().toLocaleDateString()}
      </p>
    </div>
  }

  if (verificationRequestSent === true || (userInfo.verification !== undefined && userInfo.IDIsVerified !== true) ) {
    return <div className="text-center p-5">
      <h2 className="font-bold text-2xl uppercase">Verification in progress</h2>
      <p className="text-center max-w-xl mx-auto py-2">
        Your identity verification is in progress. We expect this to take less than 24 hours. If we require any additional information we will be in touch.
      </p>
    </div>
  }

  return (<div className="text-center p-5">
    <h3 className="font-bold text-2xl uppercase">Why do we need to verify your identity?</h3>
    <p className="text-center max-w-xl mx-auto py-2">
      FlossiPay is comitted to ensuring our customers online safety. To be able to do this, along with complying with our respinsbile operating duties, we are required to verify that you are who you say you are. Please fill out the form below.
    </p>
    {errors.root ? <p className="border border-red-600 bg-red-300 text-red-600 p-6">{errors.root.message}</p> : null}
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset disabled={isLoading}>
        <div className="grid grid-cols-1 md:grid-cols-2 max-w-md mx-auto">
          <label className="block text-left md:text-right pr-2 uppercase font-bold my-auto">
              First Name:
          </label>
          <input
            type="text"
            placeholder="First Name"
            {...register(
              "forename",
              {
                required: "Please enter your first name",
                value: userInfo.firstName
              }
            )}
            className="border p-2 block w-full my-2"
            disabled
          />
          {errors.forename && <p className="text-red-600 md:col-span-2">{errors.forename.message}</p>}
          <label className="block text-left md:text-right pr-2 uppercase font-bold my-auto">
              Surname:
          </label>
          <input
            type="text"
            placeholder="Surname"
            {...register(
              "surname",
              {
                required: "Please enter your surname",
                value: userInfo.lastName
              }
            )}
            className="border p-2 block w-full my-2"
            disabled
          />
          {errors.surname && <p className="text-red-600 md:col-span-2">{errors.surname.message}</p>}
          <label className="block text-left md:text-right pr-2 uppercase font-bold my-auto">
              Email Address:
          </label>
          <input
            type="text"
            placeholder="Email"
            {...register(
              "email",
              {
                required: "Please enter your email",
                value: userInfo.email
              }
            )}
            className="border p-2 block w-full my-2"
            disabled
          />
          {errors.email && <p className="text-red-600 md:col-span-2">{errors.email.message}</p>}
          <label className="block text-left md:text-right pr-2 uppercase font-bold my-auto">
              Date of Birth:
          </label>
          <input
            type="date"
            {...register(
              "dateOfBirth",
              {
                required: "Please select your date of birth",
                validate: {
                  value: (v) => {
                    const d = new Date(v)
                    return !isNaN(d.getTime()) || "Please select a valid date"
                  },
                }
              }
            )}
            max={(() => {
              const d = new Date()
              d.setFullYear(d.getFullYear() - 18)
              return d.toISOString().split("T")[0]
            })()}
            className="border p-2 block w-full my-2"
          />
          {errors.dateOfBirth && <p className="text-red-600 md:col-span-2">{errors.dateOfBirth.message}</p>}
          <label className="block text-left md:text-right pr-2 uppercase font-bold my-auto">
            Post Code:
          </label>
          <input
            type="text"
            {...register(
              "postCode",
              {
                required: "Please enter your post code",
                pattern: {
                  value: /^([A-Z][A-HJ-Y]?\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/i,
                  message: "Please enter a valid UK post code"
                }
              }
            )}
            className="border p-2 block w-full my-2 uppercase"
          />
          {errors.postCode && <p className="text-red-600 md:col-span-2">{errors.postCode.message}</p>}
          <label className="block text-left md:text-right pr-2 uppercase font-bold my-auto">
            UK Mobile Number:
          </label>
          <div className="flex gap-0">
            <input
              type="text"
              disabled
              value="+44"
              className="border p-2 text-right border-r-0 inline-block my-2 flex-none w-1/4"
            />
            <input
              type="number"
              {...register(
                "phoneNo",
                {
                  required: "Please enter your phone number",
                  pattern: {
                    value: /^7([0-9]){9}$/,
                    message: "Please enter a valid uk mobile without the 0, 44 or +44"
                  }
                }
              )}
              placeholder="7123456789"
              min="7000000000"
              max="7999999999"
              maxLength={10}
              minLength={10}
              step={1}
              className="border p-2 inline-block my-2 flex-0 w-3/4"
              inputMode="numeric"
            />
          </div>
          {errors.phoneNo && <p className="text-red-600 md:col-span-2">{errors.phoneNo.message}</p>}
        </div>
        {/* <button type="submit" className={`inline-block text-center uppercase border hover:border-my-olive border-solid ${isLoading ? "bg-my-olive border-my-olive" : "bg-my-orange border-my-orange"} hover:bg-my-olive font-bold text-white w-full max-w-xs mx-auto py-4`}> */}
        <LinkButton type="submit" disabled={isLoading}>
          {isLoading? "Verifying..." : "Verify Now"}
        </LinkButton>
        {/* </button> */}
      </fieldset>
    </form>
  </div>)
}

export default VerifyIdentify