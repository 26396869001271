import { useFirestore, useFirestoreCollection } from "reactfire";
import { BillTypes, ContributionTypes, Event, FirestoreGiftConverter, Gift, PaymentStatus } from "../../FirestoreConverters";
import { and, collection, orderBy, query, where } from "firebase/firestore";
import Loading from "../General/Loading";
import { useState } from "react";

interface GiftListProps {
  event: Event;
  setQrToShow: (txn: Gift) => void;
}

const PendingPaymentList = ({event, setQrToShow}: GiftListProps) => {
  const firestore = useFirestore()
  // const [searchParams, setSearchParams] = useSearchParams()

  // const loadMore = () => {
  //   const base = parseInt(searchParams.get("page") || "1")
  //   const next = base+1
  //   const search = {
  //     page: `${next}`
  //   }
  //   setSearchParams(search)
  // }

  const giftsRef = collection(firestore, "events", event.id, "gifts")
    .withConverter(FirestoreGiftConverter)
  const giftsQuery = query(
    giftsRef,
    and(
      where("type", "==", ContributionTypes.BILL),
      where("status", "in",
        [
          PaymentStatus.PENDING,
          PaymentStatus.FAILED,
          PaymentStatus.CANCELLED
        ]
      )
    ),
    orderBy("giftedAt", "desc"),
    // limit(giftLimit*parseInt(searchParams.get("page") || "1")
  )

  const [copiedToClipboard, setCopiedToClipboard] = useState<false | string>(false)
  const copyBillLink = async (bill: Gift) => {
    if (bill.id === undefined) return
    const billUrl = `http://${window.location.host}/contribute-to/${event.id}/bill/${bill.id}`
    await navigator.clipboard.writeText(`${billUrl}?utm_source=jaradm&utm_medium=link`)
    setCopiedToClipboard(bill.id)
    setTimeout(() => {
      setCopiedToClipboard(false)
    }, 5000)
  }

  // const giftLimit = 10

  const {status, data: giftsData} = useFirestoreCollection(giftsQuery)

  // Loading the data
  if (status === "loading") return <div className="p-4"><Loading /></div>

  // Something went wrong
  if (status === "error") return <p className="p-2">Sorry, we couldn't load pending bills right now. Please try again later.</p>

  // Open or closed event, with no pendings
  if (giftsData.size === 0) return null

  const totalAmount: number = giftsData.docs.reduce((total, doc) => {
    return total + doc.data().giftAmount
  }, 0)

  return <>
    <div className="uppercase text-xl p-4 text-white bg-my-olive font-bold flex">
      <h3 className="flex-grow">Unpaid Bills</h3>
      <div className="text-xs md:text-sm flex-shrink my-auto text-center">
        <p className="md:inline md:mr-2">Outstanding:</p>
        <p className="md:inline">£{totalAmount.toFixed(2)}</p>
      </div>
    </div>
    <div className="px-4">
      {giftsData.docs.map((row) => {
        const contribution = row.data()
        return <div className="flex py-4 border-b last:border-b-0" key={contribution.id}>
          <div className="flex-initial w-8/12">
            {"billType" in contribution && contribution.billType === BillTypes.INSTANT ?
              <p>
                Instant Bill for
              </p>
              :
              <p>
                Bill for
                <span className="font-bold">
                  {` ${contribution.cardholderFName} ${contribution.cardholderLName}`}
                </span>
              </p>
            }
            <p className="text-xs">{contribution.giftedAt.toDate().toLocaleDateString()}</p>
            {contribution.message !== undefined && contribution.message.length > 0 ?
              <p className="text-sm md:text-md"><span className="font-bold">Ref:</span> {contribution.message}</p>
            : null}

            {contribution.status !== PaymentStatus.CANCELLED ?
              <button
                onClick={() => setQrToShow(contribution)}
                className="text-my-orange hover:underline"
              >
                Show QR
              </button>
            : null }
            {contribution.status !== PaymentStatus.FAILED ?
              <button
                onClick={() => copyBillLink(contribution)}
                className="text-my-orange hover:underline pl-4"
              >
                {copiedToClipboard === contribution.id ?
                  <span>
                    Copied!
                  </span>
                :
                <span>
                    Copy Bill Link
                  </span>
                }
              </button>
            : null }
          </div>
          <div className="flex-none w-3/12 text-right my-auto text-my-orange md:text-2xl font-bold">
            &pound;{contribution.giftAmount.toFixed(2)}
          </div>
          <div className="flex-none w-1/12 text-right my-auto">
            {contribution.status === PaymentStatus.PENDING ?
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 inline align-bottom text-gray-400">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>
            : null}
            {contribution.status === PaymentStatus.CANCELLED ?
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 inline align-bottom text-red-300">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
              </svg>
            : null}
            {contribution.status === PaymentStatus.FAILED ?
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 inline align-bottom text-red-600">
                <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>
            : null}
          </div>
        </div>
      })}
    </div>
    {/* {giftsData.size >= (giftLimit*parseInt(searchParams.get("page") || "1")) && <div><LoadMoreButton handler={loadMore} /></div>} */}
  </>
}

export default PendingPaymentList