import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import {
  FirestoreProvider,
  AuthProvider,
  useFirebaseApp,
  useInitFirestore,
  FunctionsProvider,
  AnalyticsProvider,
  StorageProvider,
  //AppCheckProvider
} from 'reactfire';
import {
  initializeFirestore,
  //enableIndexedDbPersistence,
  connectFirestoreEmulator,
} from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';

import Layout from './components/Layout/Layout';
import Home from './components/Page/Home';
import GiftPage from './components/Page/GiftToEvent';
import Dashboard from './components/Page/Dashboard';
import MyAccount from './components/Page/MyAccount';
import EventAdmin from './components/Page/EventAdmin';
import CreateEvent, { CollectionTypes } from "./components/Page/CreateEvent";
import ScrollToTop from "./components/Layout/ScrollToTop";
import PaymentConfirmation from "./components/Event/PaymentConfirmation";
import GiftSelectForm from "./components/Event/GiftSelectForm";
import Claim from "./components/Event/Claim";
import EventAdminInfo from "./components/Event/EventAdminInfo";
import Terms from "./components/Page/Terms";
import About from "./components/Page/About";
import Contact from "./components/Page/Contact";
import NotFound from "./components/Page/NotFound";
import EditEvent from "./components/Event/EditEvent";
import CookiePolicy from "./components/Page/CookiePolicy";
import ForgottenPassword from "./components/Page/ForgottenPassword";
import HandleUserAction from "./components/Page/HandleUserAction";
import Privacy from "./components/Page/Privacy";
import { getAnalytics, setAnalyticsCollectionEnabled } from "firebase/analytics";
// import ClaimBacs from "./components/Event/ClaimBacs";
// import ClaimCard from "./components/Event/ClaimCard";
// import ClaimIndex from "./components/Event/ClaimIndex";
// import { ReCaptchaV3Provider, initializeAppCheck } from "firebase/app-check";
import TypeSelect from "./components/Page/TypeSelect";
import MyDetails from "./components/Page/MyDetails";
import MyDetailsForm from "./components/User/MyDetailsForm";
import VerifyIdentify from "./components/User/VerifyIdentity";
import ManageVerification from "./components/Page/ManageVerification";
import CreateJarWrapper from "./components/Event/CreateJarWrapper";
import BillRedirect from "./components/Event/BillRedirect";
import KYC from "./components/User/KYC";
import { getStorage } from "firebase/storage";
import LinkBankAccount from "./components/User/LinkBankAccount";
import RedeemSelectBankAccount from "./components/Event/RedeemSelectBankAccount";

function App() {
  const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`

  // initialize Database and Auth with the normal Firebase SDK functions
  const authInstance = getAuth(app);
  if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_FIREBASE_EMULATOR_AUTH === "true") {
    connectAuthEmulator(authInstance, "http://localhost:9099");
  }

  const storageInstance = getStorage(app)

  const {status, data: firestoreInstance} = useInitFirestore(async (firebaseApp) => {
    const db = initializeFirestore(firebaseApp, {})
    if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_FIREBASE_EMULATOR_FIRESTORE === "true") {
      console.log("Using firestore emulator")
      connectFirestoreEmulator(db, 'localhost', 8080);
    } else {
      //await enableIndexedDbPersistence(db);
    }
    return db;
  })

  const functionsInstance = getFunctions(app, "europe-west2")
  if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_FIREBASE_EMULATOR_FUNCTIONS === "true") {
    console.log("Using functions emulator")
    connectFunctionsEmulator(functionsInstance, "localhost", 5001);
  }

  const analyticsInstance = getAnalytics(app)
  setAnalyticsCollectionEnabled(analyticsInstance, false)

  // const appCheckKey = process.env.REACT_APP_FIREBASE_APP_CHECK_TOKEN as string
  // const appCheckSdk = initializeAppCheck(app, {
  //   provider: new ReCaptchaV3Provider(appCheckKey),
  //   isTokenAutoRefreshEnabled: true,
  // })

  if (status === 'loading') {
    return <h1>Loading...</h1>
  }

  return (
    // <AppCheckProvider sdk={appCheckSdk}>
      <AuthProvider sdk={authInstance}>
        <StorageProvider sdk={storageInstance}>
          <FirestoreProvider sdk={firestoreInstance}>
            <FunctionsProvider sdk={functionsInstance}>
              <AnalyticsProvider sdk={analyticsInstance}>
                <BrowserRouter>
                  <ScrollToTop />
                  <Routes>
                    <Route path="/" element={<Layout/>}>
                      <Route index element={<Home />} />

                      <Route path="contribute-to/:eventName" element={<GiftPage/>}>
                        <Route index element={<GiftSelectForm />} />
                        <Route path="bill/:transactionId" element={<BillRedirect />} />
                        <Route path="confirmation" element={<PaymentConfirmation />} />
                      </Route>

                      <Route element={<MyAccount />}>
                        <Route path="jars" element={<Dashboard />}/>
                        <Route path="my-account" element={<MyDetails />}>
                          <Route index element={<MyDetailsForm />} />
                          <Route path="verify" element={<VerifyIdentify />} />
                          <Route path="link-bank" element={<LinkBankAccount />} />
                        </Route>
                        {/* A component needs adding here so I can useOutletContext */}
                        <Route path="create-jar" element={<CreateJarWrapper />}>
                          <Route index element={<TypeSelect />}/>
                          <Route path="gift" element={<CreateEvent type={CollectionTypes.GIFTING} />}/>
                          <Route path="general" element={<CreateEvent type={CollectionTypes.GENERAL} />}/>
                        </Route>
                        <Route path="jars/:eventName" element={<EventAdmin />}>
                          <Route index element={<EventAdminInfo />}/>
                          <Route path="redeem" element={<Claim />}>
                            <Route index element={<RedeemSelectBankAccount />}/>
                            {/* <Route index element={<ClaimCard />}/> */}
                            {/* <Route path="card" element={<ClaimCard />}/> */}
                            {/* <Route path="bacs" element={<ClaimBacs />}/> */}
                          </Route>
                          <Route path="edit" element={<EditEvent />} />
                        </Route>
                        <Route path="kyc/:userId?" element={<KYC />} />
                      </Route>

                      <Route path="verification/:userId" element={<ManageVerification />} />

                      <Route path="forgotten-password" element={<ForgottenPassword />} />
                      <Route path="user-action" element={<HandleUserAction />} />
                      <Route path="terms" element={<Terms />} />
                      <Route path="about" element={<About />} />
                      <Route path="contact" element={<Contact />} />
                      <Route path="cookies" element={<CookiePolicy />} />
                      <Route path="privacy" element={<Privacy />} />
                      <Route path="*" element={<NotFound />} />
                    </Route>
                  </Routes>
                </BrowserRouter>
              </AnalyticsProvider>
            </FunctionsProvider>
          </FirestoreProvider>
        </StorageProvider>
      </AuthProvider>
    /* </AppCheckProvider> */
  );

}

export default App;