import { Link } from "react-router-dom";
import NewsletterSubscribeForm from "../Page/Home/NewsletterSubscribeForm";

function MainNav() {
  return (
    <div className="bg-my-orange">
      <div className="footer grid grid-cols-1 md:grid-cols-6 max-w-screen-lg mx-auto gap-4 p-4 pb-0">
        <div className="logo text-left bg-nav-logo-white bg-no-repeat bg-left bg-contain h-20 md:h-auto">
          <h1 className="invisible">
            Flossi Pay - Collecting money online made easy!
          </h1>
        </div>
        <div className="text-left uppercase md:col-span-3 font-beloved">
          <h4 className="uppercase text-xl border-b-2 border-b-white">
            Quick Links
          </h4>
          <div className="grid grid-cols-1 md:grid-cols-2 text-sm">
          <ul className="pt-2 md:py-2">
            <li>
              <Link to="/" className="hover:text-my-grey">Home</Link>
            </li>
            <li>
              <Link to="/create-jar" className="hover:text-my-grey">Create Jar</Link>
            </li>
            <li>
              <Link to="/jars" className="hover:text-my-grey">My Jars</Link>
            </li>
            <li>
              <Link to="/my-account" className="hover:text-my-grey">My Account</Link>
            </li>
          </ul>{/*
          <ul className="py-0 md:py-2">
          <li>
          <Link to="/about" className="hover:text-my-grey">About Us</Link>
          </li>
          <li>
          <Link to="/faq" className="hover:text-my-grey">FAQ's</Link>
          </li>
          <li>
          <Link to="/how-it-works" className="hover:text-my-grey">How it works</Link>
          </li>
          <li>
          <Link to="/contact" className="hover:text-my-grey">Contact Us</Link>
          </li>
        </ul>*/}
          <ul className="py-0 md:py-2">
            <li>
              <Link to="/my-account" className="hover:text-my-grey">Login</Link>
            </li>
            <li>
              <Link to="/terms" className="hover:text-my-grey">Terms &amp; Conditions</Link>
            </li>
            <li>
              <Link to="/privacy" className="hover:text-my-grey">Privacy Policy</Link>
            </li>
          </ul>
          </div>
        </div>
        <div className="text-left md:col-span-2">
          <h4 className="uppercase text-xl border-b-2 border-b-white font-beloved">
            Keep up to date
          </h4>
          <div className="py-2">
            <NewsletterSubscribeForm />
          </div>
          <div className="text-center">
            <a href="https://www.facebook.com/profile.php?id=100081466097335" className="inline-block w-8 mx-1">
              <img src="/img/socials/fb-grey.png" alt="Flossi Pay on Facebook" />
            </a>
            <a href="https://www.instagram.com/flossi_pay?igsh=MXJleTAwdmE0OHVndQ%3D%3D&utm_source=qr" className="inline-block w-8 mx-1">
              <img src="/img/socials/ig-grey.png" alt="Flossi Pay on Instagram" />
            </a>
          </div>
        </div>
      </div>
      <div className="pb-1 px-1 text-xs text-center">
        <p>
          Flossi Pay Limited &copy; {(new Date()).getFullYear()}. All rights reserved.
        </p>
        <p>
          Payments managed by our partners Cashflows Europe Limited (FCA register reference 900006)​
        </p>
      </div>
    </div>
  );
}

export default MainNav