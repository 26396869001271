import { useOutletContext, useParams } from "react-router-dom"
import Loading from "../General/Loading"
import { doc, DocumentReference, DocumentSnapshot, FirestoreError, onSnapshot } from "firebase/firestore"
import { useEffect, useState } from "react"
import { Event, FirestoreGiftConverter, Gift, PaymentStatus } from "../../FirestoreConverters"
import { useFirestore } from "reactfire"
import LinkButton from "../General/LinkButton"

const BillRedirect = () => {
  const {eventInfo} = useOutletContext<{eventInfo: Event}>()
  const {transactionId} = useParams()

  const firestore = useFirestore()
  const [transaction, setTransaction] = useState<Gift | undefined | false>(undefined)
  useEffect(() => {

    const streamGift = (giftRef: DocumentReference<Gift>, snapshotHandler: (snapshot: DocumentSnapshot<Gift>) => void, errorHandler: (e: FirestoreError) => void ) => {
      return onSnapshot(giftRef, snapshotHandler, errorHandler)
    }

    if (transactionId === undefined) return

    const ref = doc(firestore, "events", eventInfo.id, "gifts", transactionId)
      .withConverter(FirestoreGiftConverter)

    const unsub = streamGift(
      ref,
      (snapshot) => {
        console.log("got new snapshot", {snapshot})
        const data = snapshot.data()

        if (data === undefined) return setTransaction(false)
        if (data !== undefined && data.paymentJob === undefined) return setTransaction(false)
        setTransaction(data)
      },
      (e) => {
        setTransaction(false)
        console.log(e)
      })
    return unsub
  }, [eventInfo, transactionId, firestore])

  useEffect(() => {
    if (transaction === false) return
    if (transaction === undefined || transaction.paymentJob === undefined) return
    if (
      transaction.status === PaymentStatus.CANCELLED
      || transaction.status === PaymentStatus.PAID
      || transaction.status === PaymentStatus.FAILED
    ) return

    window.location.href = transaction.paymentJob.links.action.url
  }, [transaction])

  // No txn or no payment job
  if (
    transaction === false
    || (transaction !== undefined && transaction.paymentJob === undefined)
  ) {
    return <p className="py-4">Something has gone wrong, please try again later. If the problem persists please contact the jar owner</p>
  }

  // PAID!
  if (
    transaction !== undefined
    && transaction.status === PaymentStatus.PAID
  ) {
    return <div>
      <h1 className="uppercase font-bold text-xl">Thank you</h1>
      <p className="py-4">This bill has already been paid</p>
    </div>
  }

  // CANCELLED
  if (
    transaction !== undefined
    && transaction.status === PaymentStatus.CANCELLED
  ) {
      return <div>
      <h1 className="uppercase font-bold text-xl">Bill Cancelled</h1>
      <p className="py-4">Payment has been cancelled, please contact the jar owner to resend the bill</p>
    </div>
  }

  // FAILED
  if (
    transaction !== undefined
    && transaction.paymentJob !== undefined
    && transaction.status === PaymentStatus.FAILED
  ) {
    return <div>
      <h1 className="uppercase font-bold text-xl">Bill Payment</h1>
      <p className="py-4">Previous payment attempt failed. Please either try again or contact the jar owner</p>
      <LinkButton type="link" url={transaction.paymentJob.links.action.url}>Try again</LinkButton>
    </div>
  }

  // Do redirect
  return <div>
    <h1 className="uppercase font-bold text-xl">Bill Payment</h1>
    <p>Please wait while we redirect you for payment</p>
    <Loading />
  </div>
}

export default BillRedirect