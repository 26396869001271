import LinkBankAccountForm from "./LinkBankAccountForm"
import { useOutletContext } from "react-router-dom"
import { MyAccountProps } from "../Page/MyAccount"
import LinkButton from "../General/LinkButton"

const LinkBankAccount = () => {
  const {userInfo} = useOutletContext<MyAccountProps>()
  return <div>
    <h1 className="text-center text-2xl pb-2 uppercase font-bold">
      Link Bank Account
    </h1>

    {userInfo.acquiredCustomerId === undefined ?
      <>
        <p className="p-2">
          Enter your bank account details below to link your bank account
        </p>
        <LinkBankAccountForm />
      </>
    :
      <>
        <p className="pb-2">Your bank account has been linked.</p>
        <LinkButton type="link" url="/my-account">
          Back to My Account
        </LinkButton>
      </>
    }
  </div>
}

export default LinkBankAccount