import { logEvent } from "firebase/analytics";
import { MutableRefObject, useRef } from "react";
import { useAnalytics } from "reactfire";
import LinkButton from "../General/LinkButton";
import { IProps, QRCode as QR } from 'react-qrcode-logo';

interface Props {
  eventUrl: string;
  eventName: string;
  showDownloadButton: boolean;
}

const QRCode = ({eventUrl, eventName, showDownloadButton}: Props) => {
  const analytics = useAnalytics()

  const ref = useRef<QR>();
  const qrCodeProps: IProps = {
    value: eventUrl,
    logoImage: "/img/logo-orange.png",
    removeQrCodeBehindLogo: true,
    logoPadding: 20,
    logoPaddingStyle: "circle",
    qrStyle: "fluid",
    size: 400,
    fgColor: "#59696a",
    eyeColor: "#ef7d62",
    eyeRadius: 2,
    quietZone: 0,
    ecLevel: "M",
    style: {
      width: "100%",
      height: "100%"
    }
  }

  const downloadQrCode = () => {
    logEvent(analytics, "file_download", {
      link_url: eventUrl
    })
    ref.current?.download("png", eventName)
  }

  const dlProps = {...qrCodeProps}
  dlProps.size = 1024
  dlProps.logoPadding = 50
  dlProps.quietZone = 10

  const dlQr = <QR
    {...dlProps}
    ref={ref as MutableRefObject<QR>}
  />

  const qr = <QR
    {...qrCodeProps}
  />

  return (<>
    {showDownloadButton === true ?
      <LinkButton type="button" clickHandler={downloadQrCode}>Download QR Code</LinkButton>
    : null}
    <div className="qr-code py-4 mx-auto">
      {qr}
    </div>
    <div className="hidden">
      {dlQr}
    </div>
  </>)
}

export default QRCode