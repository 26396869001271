import { PropsWithChildren } from "react";

interface ModalProps {
  title: String;
  closeFunction: (show: boolean) => void;
}

const Modal = ({
  title,
  closeFunction,
  children
}: PropsWithChildren<ModalProps>) => {

  return <div className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full max-h-full bg-my-grey bg-opacity-80"
    // onClick={() => closeFunction(false)}
  >
    <div className="relative p-4 w-full max-w-2xl max-h-full mx-auto flex h-screen">
      {/* <!-- Modal wrapper --> */}
      <div className="relative flex-1 bg-white rounded-lg shadow p-4 my-auto">
        {/* <!-- Modal close button --> */}
        <button type="button" className="absolute top-0 right-0 m-4 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
          onClick={() => closeFunction(false)}
        >
          <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
          </svg>
        </button>

        {/* <!-- Modal header --> */}
        <div className="flex items-center justify-between p-4 md:p-5 border-b">
          <h3 className="text-xl font-bold uppercase">
            {title}
          </h3>
        </div>

        {/* <!-- Modal Content --> */}
        {/* <div className="px-4"> */}
          {children}
        {/* </div> */}
      </div>
    </div>
  </div>
}

export default Modal