import { useOutletContext } from "react-router-dom"
import { MyAccountProps } from "../Page/MyAccount"
import { SubmitHandler, useForm } from "react-hook-form"
import { useState } from "react"
import { FirestoreUserConverter } from "../../FirestoreConverters"
import { doc, updateDoc } from "firebase/firestore"
import { useFirestore } from "reactfire"
import { sendEmailVerification, updateEmail, updateProfile } from "firebase/auth"
import LinkButton from "../General/LinkButton"

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
}

const MyDetailsForm = () => {
  const {authUser, userInfo, setDocumentTitle, setSubTitle} = useOutletContext<MyAccountProps & {event: Event}>()
  setDocumentTitle("Manage your account")
  setSubTitle("")

  const firestore = useFirestore()

  const [isSendingVerification, setIsSendingVerification] = useState(false)
  // Send the verification email
  const sendVerification = async () => {
    if (!userInfo) return
    setIsSendingVerification(true)
    await sendEmailVerification(authUser)
    setIsSendingVerification(false)
  }

  const { register, handleSubmit, formState: { errors }, setError } = useForm<FormValues>({
    mode: 'onChange'
  })

  const [isDetailsLoading, setIsDetailsLoading] = useState<boolean>(false)
  const onSubmit: SubmitHandler<FormValues> = async (data) => {

    const userRef = doc(firestore, `users/${userInfo.id}`).withConverter(FirestoreUserConverter)

    setIsDetailsLoading(true)
    try {
      console.log(data)

      const {email, firstName} = userInfo

      if (data.email !== email) {
        // update the users email address
        await updateEmail(authUser, data.email)
      }

      if (data.firstName !== firstName) {
        // update the users display name
        await updateProfile(authUser, {
          displayName: data.firstName
        })
      }

      await updateDoc(userRef, {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName
      })
    } catch (error) {
      setError("email", {
        message: "Failed to update your information. Please try again"
      })
      console.log(error)
    }
    setIsDetailsLoading(false)
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
      <div>
        <h3 className="uppercase text-xl p-4 text-white bg-my-orange text-left font-bold">My Details</h3>
        <form onSubmit={handleSubmit(onSubmit)} className="p-2">
        <fieldset disabled={isDetailsLoading}>
          <div className="py-1">
            <input
              type="text"
              placeholder="First name"
              {...register(
                "firstName",
                {
                  required: "Please enter your first name",
                  maxLength: 80,
                  value: userInfo.firstName
                },
              )}
              className="border p-2 w-full"
            />
            {errors.firstName && <p className="text-red-600">{errors.firstName.message}</p>}
          </div>

          <div className="py-1">
            <input
              type="text"
              placeholder="Last name"
              {...register(
                "lastName",
                {
                  required: "Please enter your last name",
                  maxLength: 100,
                  value: userInfo.lastName
                }
              )}
              className="border p-2 w-full"
            />
            {errors.lastName && <p className="text-red-600">{errors.lastName.message}</p>}
          </div>

          <div className="py-1">
            <input
              type="text"
              placeholder="Email"
              {...register(
                "email",
                {
                  required: "Please enter your email",
                  pattern: /^\S+@\S+$/i,
                  value: userInfo.email
                }
              )}
              className="border p-2 w-full"
            />
            {errors.email && <p className="text-red-600">{errors.email.message}</p>}
          </div>

          <div className="text-center pt-1">
            {/* <button type="submit" className="inline-block text-center uppercase border border-my-olive hover:border-my-orange border-solid bg-my-olive hover:bg-my-orange font-bold text-white w-full max-w-xs mx-auto py-4"> */}
            <LinkButton
              type="submit"
              disabled={isDetailsLoading}
              backgroundColour="my-olive"
            >
              {isDetailsLoading ? "Updating..." : "Update"}
            </LinkButton>
            {/* </button> */}
          </div>
        </fieldset>

      </form>
      </div>
      <div className="text-center pb-2">
        <h3 className="uppercase text-xl p-4 text-white bg-my-orange text-left font-bold">Account Status</h3>
        <div className="grid grid-cols-2 gap-2">
          <p className="text-justify col-span-2 p-2">
            To be able to withdraw contributions from your jar, you are required to verify both your email address and identity, and link a bank account.
          </p>

            <div className="font-bold text-right">
              <p>Email Address:</p>
            </div>
            <div className="text-left">
              <p>{authUser.emailVerified === false? "Unverified" : "Verified"}</p>
            </div>
            {authUser.emailVerified === false?
              <div className="col-span-2">
                <LinkButton
                  type="button"
                  clickHandler={sendVerification}
                  disabled={isSendingVerification}
                  backgroundColour="my-olive"
                >
                  {isSendingVerification ===true ? "Sending..." : "Send verification"}
                </LinkButton>
              </div>
            :
              null
            }

            <div className="font-bold text-right">
              <p>Identity:</p>
            </div>
            <div className="text-left">
              <p>{userInfo.IDIsVerified === true ? "Verified" : userInfo.verification !== undefined ? "In Progress" : "Unverified"}</p>
            </div>
            {userInfo.IDIsVerified !== true && userInfo.verification === undefined ?
              <div className="col-span-2">
                <LinkButton
                  type="link"
                  url="verify"
                  backgroundColour="my-olive"
                >
                  Verify Now
                </LinkButton>
              </div>
            :
              null
            }

            <div className="font-bold text-right">
              <p>Bank Account:</p>
            </div>
            <div className="text-left">
              <p>{userInfo.acquiredCustomerId === undefined ? "Not Linked" : "Linked" }</p>
            </div>
            {userInfo.acquiredCustomerId === undefined ?
              <div className="col-span-2">
                <LinkButton
                  type="link"
                  url="link-bank"
                  backgroundColour="my-olive"
                >
                  Link Now
                </LinkButton>
              </div>
            :
              null
            }
        </div>
      </div>
    </div>
  )
}
export default MyDetailsForm