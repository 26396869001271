import { BillTypes, ContributionTypes, Gift, PaymentStatus } from "../../FirestoreConverters"

type ContributionCardProps = {
  contribution: Gift;
}

const GeneralContributionCard = ({contribution}: ContributionCardProps) => {
  const {
    giftAmount,
    message,
    type,
    cardholderLName,
    cardholderFName,
    giftedAt,
    status,
  } = contribution
  return <div className="flex">
    <div className="flex-initial w-8/12">

      {type === ContributionTypes.REDEEM ?
        <p>
          <span className="font-bold">You</span> withdrew
        </p>
      : null}

      {type === ContributionTypes.BILL ?
        "billType" in contribution && contribution.billType === BillTypes.INSTANT ?
          <p>
            Instant Bill
          </p>
        :
          <p>
            Bill for
            <span className="font-bold">
              {` ${cardholderFName} ${cardholderLName}`}
            </span>
          </p>
      : null}

      {type === ContributionTypes.GENERAL ?
        <p>
          <span className="font-bold">
            {cardholderFName} {cardholderLName}
          </span> paid
        </p>
      : null}
      <p className="text-xs">{giftedAt.toDate().toLocaleDateString()}</p>
      {message !== undefined && message.length > 0 ?
        <p className="text-sm md:text-md">
          <span className="font-bold">Ref:</span> {message}
        </p>
      : null}
    </div>
    <div className="flex-none w-3/12 text-right my-auto text-my-orange md:text-2xl font-bold">
      {type === ContributionTypes.REDEEM && giftAmount < 0 ?
        `- £${(giftAmount * -1).toFixed(2)}`
      :
        `£${giftAmount.toFixed(2)}`
      }
    </div>
    <div className="flex-none w-1/12 text-right my-auto">
      {status === PaymentStatus.PENDING ?
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 inline align-bottom text-gray-400">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>
      : null}
      {status === PaymentStatus.PAID ?
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 inline align-bottom text-my-olive">
          <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>
      : null}
      {status === PaymentStatus.FAILED ?
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 inline align-bottom text-red-500">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
        </svg>
      : null}

    </div>
  </div>
}
export default GeneralContributionCard